import "./CoverLetter.css";
import SocialLink from "./SocialLink";
import { FaGithub, FaLinkedin, FaLink } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

const coverLetterText = (
  <p>
    {`

Dear Hiring Manager,

My name is Nicholas Vadasz and I am a junior at Brown University studying Computer Science with a focus in UI/UX and Software Design. I recently came across Ramp's posting for a Frontend Software Engineering Intern. I am very interested in the position and believe that my experience and skills would make me a great fit for the team.

For more than a year, I have been working as the`}
    <b style={{ color: "black", fontWeight: "700" }}>
      {" "}
      creator, designer, and maintainer of a school-based social
    </b>{" "}
    {`media called Dear Blueno (`}{" "}
    <a
      href="https://dearblueno.net"
      style={{ color: "black", fontWeight: "700" }}
    >
      dearblueno.net
    </a>{" "}
    {`). Built on the MERN stack with Typescript, Dear Blueno has been a massive learning experience for me and has taught me just how important a \
well-designed and well-architected system is to the success of a project. Dear Blueno maintains more than `}
    <b style={{ color: "black", fontWeight: "700" }}>20,000 visits per week</b>{" "}
    {`and I believe a large part of that success is due to how its consistent and reliable design \
has made it easy to use, easy to maintain, and easy to expand upon.

As the`}
    <b style={{ color: "black", fontWeight: "700" }}>
      {" "}
      Head Teaching Assistant for Brown's class on Blockchains &
      Cryptocurrencies,
    </b>{" "}
    {`I have had firsthand experience interfacing with and creating fintech software, such as modeling \
transaction channels akin to Bitcoins' Lightning Network. A class predominantly taken by graduate students, \
I was tasked with creating projects extremely close in implementation to their industry counterparts. Through this experience, \
I gained a deep understanding of the importance of security, performance, and reliability in fintech software.

Most of my projects and jobs have involved a specific focus that I think Ramp very much shares: \
`}
    <b style={{ color: "black", fontWeight: "700" }}>
      an intersection of great design and great technology.
    </b>{" "}
    {`I believe that my experience with Dear Blueno and my work as an HTA have given me the skills and the \
perspective to contribute to Ramp's mission in a meaningful way. I am excited to help Ramp \
continue growing, and I hope to lend my eagerness and creativity to an equally motivated team.

Thank you for your time and consideration. I look forward to hearing from you.

Regards,
Nicholas Vadasz
903-931-2613
nicholas_vadasz@brown.edu
`}
  </p>
);

function CoverLetter() {
  return (
    <div className="CoverLetter">
      <h1 className="Name">Nicholas Vadasz</h1>
      <div className="SocialLinks">
        <SocialLink
          iconType={FaGithub}
          link="https://github.com/nicholasvadasz"
          text="nicholasvadasz"
        />
        <SocialLink
          iconType={FaLinkedin}
          link="https://www.linkedin.com/in/nicholasvadasz/"
          text="in/nicholasvadasz"
        />
        <SocialLink
          iconType={FaLink}
          link="https://nicholas.codes"
          text="nicholas.codes"
        />
        <SocialLink
          iconType={GrMail}
          link="mailto:nicholas_vadasz@brown.edu"
          text="nicholas_vadasz@brown.edu"
        />
      </div>
      <div className="BlankSpace" />
      <hr className="Line" />
      <hr className="Line" />
      <p className="coverBody">{coverLetterText}</p>
    </div>
  );
}

export default CoverLetter;
