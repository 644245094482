import "./App.css";
import HomePage from "./components/pages/HomePage";
import AboutPage from "./components/pages/AboutPage";
import ExperiencePage from "./components/pages/ExperiencePage";
import ProjectsPage from "./components/pages/ProjectsPage";
import PhotoPage from "./components/pages/PhotoPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Resume from "./components/pages/Resume";
import CoverLetter from "./components/pages/CoverLetter";
import Portfolio from "./components/pages/Portfolio";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
          <Route path="/projects" element={<ProjectsPage />}></Route>
          <Route path="/resume" element={<Resume />} />
          <Route path="/coverletter" element={<CoverLetter />} />
          <Route path="/photos" element={<PhotoPage />}></Route>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
