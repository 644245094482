import "./PhotoPage.css";
import Photo1 from "../images/photo1.png";
import Photo2 from "../images/photo2.png";
import Photo3 from "../images/photo3.png";
import Photo4 from "../images/photo4.png";
import Photo5 from "../images/photo5.png";
import { useState } from "react";

function PhotoPage() {
  const photos = [
    {
      source: Photo1,
      caption: "Taken on a Polaroid SX-70",
    },
    {
      source: Photo2,
      caption: "Taken on a Polaroid SX-70",
    },
    {
      source: Photo3,
      caption: "Taken on a Polaroid SX-70",
    },
    {
      source: Photo4,
      caption: "Taken on a Polaroid SX-70",
    },
    {
      source: Photo5,
      caption: "Taken on a Polaroid SX-70",
    },
  ];

  const [currentPhoto, setCurrentPhoto] = useState(photos[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="PhotoPage">
      <div className="CurrentPhoto">
        <div className="PhotoAndNav">
          <p
            className="Nav"
            onClick={() => {
              if (currentIndex - 1 !== -1) {
                setCurrentPhoto(photos[currentIndex - 1]);
                setCurrentIndex(currentIndex - 1);
              } else {
                setCurrentPhoto(photos[photos.length - 1]);
                setCurrentIndex(photos.length - 1);
              }
            }}
          >
            {"<"}
          </p>
          <img
            src={currentPhoto.source}
            alt={photos.indexOf(currentPhoto).toString()}
            width="510px"
            height="630px"
            style={{
              padding: "3px",
              border: "1px dashed black",
              marginTop: "10px",
            }}
          />
          <p
            className="Nav"
            onClick={() => {
              if (currentIndex + 1 < photos.length) {
                setCurrentPhoto(photos[currentIndex + 1]);
                setCurrentIndex(currentIndex + 1);
              } else {
                setCurrentPhoto(photos[0]);
                setCurrentIndex(0);
              }
            }}
          >
            {">"}
          </p>
        </div>
        <p className="Caption">{currentPhoto.caption}</p>
      </div>
      <div className="PhotoList">
        {photos.map((photo) => {
          return (
            <img
              className="Photo"
              src={photo.source}
              alt={photos.indexOf(photo).toString()}
              onClick={() => {
                setCurrentPhoto(photo);
                setCurrentIndex(photos.indexOf(photo));
              }}
              style={{
                width: "85px",
                height: "105px",
                border: "1px solid black",
                margin: "5px",
                cursor: "pointer",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default PhotoPage;
