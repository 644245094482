import "./BulletPoint.css";

type BulletPointProps = {
  text: string | JSX.Element;
  link?: string;
};

function BulletPoint(props: BulletPointProps) {
  return (
    <div className="BulletPoint">
      <p className="Point">•</p>
      {/* if props text is of type jsx element */}
      {typeof props.text === "string" ? (
        <p className="BulletText">{props.text}</p>
      ) : (
        props.text
      )}
      {props.link ? (
        <a
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
          className="BulletLink"
        >
          {props.link}
        </a>
      ) : null}
    </div>
  );
}

export default BulletPoint;
