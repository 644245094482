import { IconType } from "react-icons";
import "./SocialLink.css";

type SocialLinkProps = {
  iconType?: IconType;
  link: string;
  text: string;
};

function SocialLink(props: SocialLinkProps) {
  return (
    <div className="SocialLinkContainer">
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="SocialLink"
      >
        {props.iconType ? <props.iconType /> : null}
        <p className="SocialLinkText">{props.text}</p>
      </a>
    </div>
  );
}

export default SocialLink;
