import "./HomePage.css";
import NameRow from "../NameRow";
import LinkRow from "../LinkRow";
import { useState, useEffect } from "react";

function HomePage() {
  const [starString1, setStarString1] = useState(
    "******************************"
  );
  const [starString2, setStarString2] = useState(
    "******************************"
  );
  const [mouseWithin, setMouseWithin] = useState(false);
  const randChars = "#&%^$@!~";
  // change one of the characters in the star string to something random every 100ms
  useEffect(() => {
    const interval = setInterval(() => {
      if (mouseWithin) {
        const randIndex = Math.floor(Math.random() * starString1.length);
        const randChar =
          randChars[Math.floor(Math.random() * randChars.length)];
        // pick randomly between the two strings
        if (Math.random() < 0.5) {
          setStarString1(
            starString1.substring(0, randIndex) +
              randChar +
              starString1.substring(randIndex + 1)
          );
        } else {
          setStarString2(
            starString2.substring(0, randIndex) +
              randChar +
              starString2.substring(randIndex + 1)
          );
        }
      }
    }, 200);
    return () => clearInterval(interval);
  }, [mouseWithin, starString1, starString2]);

  return (
    <div className="HomePage">
      <div
        className="MiddleContainer"
        onMouseEnter={() => setMouseWithin(true)}
        onMouseLeave={() => setMouseWithin(false)}
      >
        <p className="TopBar">{starString1}</p>
        <NameRow within={mouseWithin} />
        <LinkRow within={mouseWithin} />
        <p className="BotBar">{starString2}</p>
      </div>
    </div>
  );
}

export default HomePage;
