import "./AboutPage.css";
import SelfLogo from "../images/self.webp";
import Self2Logo from "../images/self2.webp";
import { Link } from "react-router-dom";

function AboutPage() {
  return (
    <div className="AboutPage">
      <Link to="/" style={{ textDecoration: "none" }}>
        <p className="HomeButton">home</p>
      </Link>
      <img className="SelfLogo" src={SelfLogo} alt="Self Logo" />
      <img className="Self2Logo" src={Self2Logo} alt="Self Logo" />
      <div className="MainText">
        <p className="Body1">
          My name is Nicholas Vadasz and I am a junior at Brown University
          studying Computer Science. My experience lies in fullstack
          development, decentralized systems, UIUX, and software engineering.
          Besides Computer Science, some of my passions include art, design, and
          music. I am a principal percussionist for the Brown University
          Orchestra in which I perform frequently. Besides that, I can often be
          found messing around with Linux distributions, taking photographs, or
          playing chess.
        </p>
        <p className="Body2">
          <p className="LinkHeader">LINKS</p>
          <a className="Link" href="https://github.com/nicholasvadasz">
            GitHub
          </a>
          <a
            className="Link"
            href="https://www.linkedin.com/in/nicholasvadasz/"
          >
            LinkedIn
          </a>
          <a className="Link" href="mailto:nicholas_vadasz@brown.edu">
            Email
          </a>
          <a className="Link" href="/NicholasVadaszResume.pdf">
            Resume
          </a>
          {/* <a className="Link" href="/portfolio">
            Portfolio
          </a> */}
          {/* <a className="Link" href="/photos">
            Photos
          </a> */}
        </p>
        <br />
      </div>
    </div>
  );
}

export default AboutPage;
