import "./Resume.css";
import SocialLink from "./SocialLink";
import BulletPoint from "./BulletPoint";
import { FaGithub, FaLinkedin, FaLink } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

// MARGINS FOR PRINT ON MAC = .17, .2, .17

function Resume() {
  let Bullet1 = (
    <p className="BulletText">
      Head Teaching Assistant under Professor Maurice Herlihy. Created both
      technical and conceptual projects, as well as developed educational
      content for the class which was taken by <b>~30% graduate students</b>.
    </p>
  );
  let Bullet2 = (
    <p className="BulletText">
      Helped design, revise, and grade projects such as Chain, an{" "}
      <b>optimized storage system written in Go</b> for distributed system
      applications, as well as homework assignments on{" "}
      <b>concurrency, hashing, efficient data structures,</b> and more.
    </p>
  );
  let Bullet3 = (
    <p className="BulletText">
      Answered student inquiries regarding <b>Linux systems</b>, shell
      navigation, and general software issues.
    </p>
  );
  let Bullet3AndHalf = (
    <p className="BulletText">
      Managed the use, reservations, and programming of{" "}
      <b>Brown's Grid Resources</b>, including 25 GPU servers and 107 nodes.
    </p>
  );
  let Bullet4 = (
    <p className="BulletText">
      Led studios on topics including{" "}
      <b>design thinking, prototyping, critique, and frontend development</b>.
    </p>
  );
  let Bullet5 = (
    <p className="BulletText">
      Created assignments and guides that covered technologies such as{" "}
      <b>Git, Flutter, Figma, and React</b>.
    </p>
  );
  let Bullet6 = (
    <p className="BulletText">
      Wrote <b>comprehensive tests</b> to ensure the reliability and robustness
      of existing site infrastructure.
    </p>
  );

  return (
    <div className="Resume">
      <h1 className="ResumeName">Nicholas Vadasz</h1>
      <div className="SocialLinks">
        <SocialLink
          iconType={FaGithub}
          link="https://github.com/nicholasvadasz"
          text="nicholasvadasz"
        />
        <SocialLink
          iconType={FaLinkedin}
          link="https://www.linkedin.com/in/nicholasvadasz/"
          text="in/nicholasvadasz"
        />
        <SocialLink
          iconType={FaLink}
          link="https://nicholas.codes"
          text="nicholas.codes"
        />
        <SocialLink
          iconType={GrMail}
          link="mailto:nicholas_vadasz@brown.edu"
          text="nicholas_vadasz@brown.edu"
        />
      </div>
      <div className="Section">
        <h2 className="Header">EDUCATION</h2>
        <hr className="Line" />
        <div className="SubheaderTimePair">
          <h3 className="SubHeader">Brown University</h3>
          <h3 className="SubHeader">September 2020 - Present</h3>
        </div>
        <p className="EducationSubscript">B.Sc. in Computer Science</p>
      </div>
      <div className="Section">
        <h2 className="Header">PROJECTS</h2>
        <hr className="Line" />

        <div className="ProjectSubheaderTimePair">
          <div className="GHub">
            <h3 className="SubHeader">Dear Blueno</h3>
            <a
              className="SubHeader"
              href="https://github.com/Dear-Blueno/dearblueno"
            >
              [Dear-Blueno/dearblueno]
            </a>
          </div>
          <h3 className="SubHeader">November 2021 - Present</h3>
        </div>
        <p className="ProjBody">
          Founded an anonymous post board for students and community members of
          Brown University. Dear Blueno is built with a{" "}
          <b>React, Next.js (TypeScript) frontend</b> and an{" "}
          <b>Express, MongoDB backend</b>. Features include{" "}
          <b>Google OAuth login</b>, an XP system based on user interaction,{" "}
          <b>multiple feed sorting algorithms</b>, and a streamlined moderator
          experience that unifies both Google Forms and native submissions in
          the backend. Dear Blueno maintains more than{" "}
          <b>20,000 visits per week</b>.
        </p>
        <div className="ProjectSubheaderTimePair">
          <div className="GHub">
            <h3 className="SubHeader">Raytracer</h3>
            <a
              className="SubHeader"
              href="https://github.com/nicholasvadasz/raytracer"
            >
              [nicholasvadasz/raytracer]
            </a>
          </div>
          <h3 className="SubHeader">September 2022 - October 2022</h3>
        </div>
        <p className="ProjBody">
          A raytracer written in C++ with the <b>OpenGL Mathematics</b> library
          and Qt assets. Implements the Phong Lighting Model and supports point,
          area, and directional lighting, as well as{" "}
          <b>UV texturing, shadows, refractions, and reflections</b>.
        </p>
        <div className="ProjectSubheaderTimePair">
          <div className="GHub">
            <h3 className="SubHeader">Chain</h3>
            <a
              className="SubHeader"
              href="https://github.com/cs1951L-spring2022/chain-stencil"
            >
              [cs1951L-spring2022/chain-stencil]
            </a>
          </div>
          <h3 className="SubHeader">December 2021 - May 2022</h3>
        </div>
        <p className="ProjBody">
          A project created for CS1951L teaching purposes, Chain is a
          <b> distributed storage solution</b> written in Go that uses Google's
          protobufs, LevelDB, as well as both a cache and database component to
          provide a fast and scalable system.
        </p>
        {/* <div className="ProjectSubheaderTimePair">
          <div className="GHub">
            <h3 className="SubHeader">1951LBot</h3>
            <a
              className="SubHeader"
              href="https://github.com/nicholasvadasz/1951LBot"
            >
              [nicholasvadasz/1951LBot]
            </a>
          </div>
          <h3 className="SubHeader">November 2021 - January 2022</h3>
        </div>
        <p className="ProjBody">
          A <b>Discord bot written in Python.</b> Features include allowing
          students to easily query for project/homework handouts, implementation
          of anonymous chat messages for help in debugging, and{" "}
          <b>Google Calendar API integration</b> to display the most updated
          office hours, as well as notify students if they change.
        </p> */}
      </div>
      <div className="Section">
        <h2 className="Header">EXPERIENCE</h2>
        <hr className="Line" />

        <div className="ExpSubheaderTimePair">
          <h3 className="SubHeader">Head Sunlab Consultant</h3>
          <h3 className="SubHeader">January 2022 - Present</h3>
        </div>
        <BulletPoint text="Consultant of Brown University's CS Department with the responsibility of maintaining all department machines." />
        <BulletPoint text={Bullet3} />
        <BulletPoint text="Worked alongside university staff regarding department changes and the employment of all other Sunlab Consultants." />
        <BulletPoint text={Bullet3AndHalf} />

        <div className="ExpSubheaderTimePair">
          <h3 className="SubHeader">
            Blockchains and Cryptocurrencies Head TA{" "}
          </h3>
          <h3 className="SubHeader">November 2021 - Present</h3>
        </div>
        <BulletPoint text={Bullet1} />
        <BulletPoint text="Led the interview and subsequent hiring of undergraduate TA staff, as well as insured staff cohesion. Led hours, answered questions asynchronously via email, and ensured the integrity of the course's grading system." />
        <BulletPoint text={Bullet2} />
        <BulletPoint text="Worked as undergraduate TA in Spring 2022 before accepting additional responsibility as Head TA in Fall 2022." />

        <div className="ExpSubheaderTimePair">
          <h3 className="SubHeader">User Interfaces & User Experience TA</h3>
          <h3 className="SubHeader">August 2022 - December 2022</h3>
        </div>
        <BulletPoint text="Helped develop course content including lecture slides, technical studios, projects, readings, and more." />
        <BulletPoint text={Bullet4} />
        <BulletPoint text="Assisted in the development of The UX Factor, an online peer evaluation platform for grading purposes." />
        <BulletPoint text={Bullet5} />

        <div className="ExpSubheaderTimePair">
          <h3 className="SubHeader">Brown University Orchestra Webmaster </h3>
          <h3 className="SubHeader">September 2021 - Present</h3>
        </div>
        <BulletPoint text="Helped update and maintain the webpage for the largest musical group on campus, the Brown University Orchestra." />
        <BulletPoint text="Edited website components and program structure to improve the modularity of the codebase." />
        <BulletPoint text={Bullet6} />
      </div>
      <div className="Section">
        <h2 className="Header">RELEVANT COURSEWORK</h2>
        <hr className="Line" />
        <p className="Courses">
          Algorithms & Data Structures • Design & Implementation of Programming
          Languages • Blockchains & Cryptocurrencies • Computer Systems • Linear
          Algebra • Software Engineering • Discrete Structures and Probability •
          Computer Graphics • Statistical Inference
        </p>
      </div>

      <div className="Section">
        <h2 className="Header">LANGUAGES/TECHNOLOGIES</h2>
        <hr className="Line" />
        <div className="Techs">
          <p className="ProjBody">
            <strong>Languages:</strong> C, C++, Java, TypeScript, Go, Rust,
            Python, JavaScript, SQL, Dart, Bash, Solidity, HTML, Racket, CSS.
          </p>
          <p className="ProjBody">
            <strong>Technologies:</strong> MongoDB, Node, Google APIs, Git,
            Numpy, Selenium, React, React Native, Electron, Figma, OAuth 2.0,
            Express, Tailwind, tRPC, Prisma, Material UI, Maya, Blender, OpenGL,
            Manim, MATLAB, Fusion 360, Photoshop, InDesign, Illustrator, FastX.
          </p>
          <p className="ProjBody">
            <strong>Interests:</strong> Graphic Design, Digital & Analog
            Photography, Competitive Video Games, Music.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Resume;
