import "./ProjectsPage.css";
import ContentBox from "./ContentBox";
import { Link } from "react-router-dom";

const dbBody = `An anonymous posting board for Brown University students and members of the Brown community.

Built with a React (TypeScript, SCSS, Next.js) frontend and an Express, MongoDB backend. Dear Blueno makes use of Google OAuth2, \
ImgBB image hosting, Google Sheets API, Cloudflare content delivery, and nodemailer among other things. A community-focused \
social media, Dear Blueno maintains more than 20,000 visits per week. 

`;

const chainBody = `A project written for CS1951L: Blockchains and Cryptocurrencies teaching purposes.

A blockchain storage solution written in Go, modeled after Bitcoin. Chain utilizes Google's protocol buffers, LevelDB, as well as both a cache and database component to provide a fast and scalable system. \
Chain features a consensus algorithm and robust fork handling among other optimizations.

`;

const botBody = `A bot written for student-to-staff TA communication.

1951LBot is a Discord bot written in Python. Features include allowing students to easily query for project/homework handouts, implementation \
of anonymous chat messages for help in debugging, and Google Calendar API integration to display the most updated office hours, as well as notify \
students if they change.

`;

const crystalBody = `A procedural crystal geometry generator written in C++ with OpenGL and Qt assets.

Crystal geometries are generated through Perlin Noise, Lindenmayer Systems, and other computational geometry techniques. \
Geometries are rendered initially in a Qt window with OpenGL to allow for aesthetic tweaking, but can be exported to an .obj file for use in other 3D modeling softwares. 

`;

const treeBody = `An ascii tree generator written in React.
 
TreeMaker is a minimalistic React app (TypeScript, CSS) that generates festive ascii trees with twinkling ornaments based on a user's input. \
React-Router is utilized to allow for the sharing of trees via URL. Deployed with Cloudflare Pages.

`;

const hibearnationBody = `A dorm review and information aggregation site for Brown University.

Hibearnation is a dorm review site that allows users to search for dorms, view images, read reviews, and upload information about their experience. \
Built with the T3 Stack (Next.js, tRPC, Tanstack Query, React, Typescript). Deployed via Vercel with Postgres database hosted on Railway. Hibearnation was \
a prize winner at Brown's 2023 Hackathon.

`;

function ProjectsPage() {
  return (
    <div className="ProjectsPage">
      <Link to="/" style={{ textDecoration: "none" }}>
        <p className="ExpHomeButton">home</p>
      </Link>
      <div className="ContentBoxContainer">
        <ContentBox
          title="Dear Blueno"
          time="October 2021 - Present"
          body={dbBody}
          link1="https://dearblueno.net"
          link1exp="Dear Blueno"
          link2="https://github.com/Dear-Blueno/dearblueno"
          link2exp="Dear Blueno GitHub"
        />
        <ContentBox
          title="Crystals"
          time="November 2022 - December 2022"
          body={crystalBody}
          link1="https://imgur.com/a/4pj6SQS"
          link1exp="Crystals Example Renders"
          link2="https://github.com/nicholasvadasz/wsystem"
          link2exp="Crystals GitHub"
        />
        <ContentBox
          title="Chain"
          time="December 2021 - January 2022"
          body={chainBody}
          link1="https://github.com/cs1951L-spring2022/chain-stencil"
          link1exp="Chain GitHub"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="Hibearnation"
          time="January 2023"
          body={hibearnationBody}
          link1="https://hibearnation.tech"
          link1exp="Hibearnation"
          link2="https://github.com/NicholasBottone/hibearnation"
          link2exp="Hibearnation GitHub"
        />
        <ContentBox
          title="1951Bot"
          time="January 2022"
          body={botBody}
          link1="https://github.com/nicholasvadasz/1951LBot"
          link1exp="1951LBot GitHub"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="TreeMaker"
          time="December 2021"
          body={treeBody}
          link1="https://treemaker.pages.dev"
          link1exp="TreeMaker Website"
          link2="https://github.com/nicholasvadasz/TreeMaker"
          link2exp="TreeMaker GitHub"
        />
        <br />
      </div>
    </div>
  );
}

export default ProjectsPage;
