import React from "react";
import "./Portfolio.css";
import Lightbox from "yet-another-react-lightbox";
import { Link } from "react-router-dom";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";
import db2 from "../images/db2.png";
import db3 from "../images/db3.png";
import db4 from "../images/db4.png";
import db5 from "../images/db5.png";
import db6 from "../images/db6.png";
import db7 from "../images/db7.png";
import db8 from "../images/db8.png";
import hb1 from "../images/hb1.png";
import hb2 from "../images/hb2.jpg";
import hb3 from "../images/hb3.png";
import hb4 from "../images/hb4.png";
import bl1 from "../images/bl1.png";
import bl2 from "../images/bl2.png";
import bl3 from "../images/bl3.png";
import bl4 from "../images/bl4.png";
import bb1 from "../images/bb1.gif";
import bb2 from "../images/bb2.gif";
import bb3 from "../images/bb3.gif";
import bb4 from "../images/bb4.gif";
import ui1 from "../images/ui1.png";
import ui2 from "../images/ui2.png";

export default function Portfolio() {
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [openFour, setOpenFour] = useState(false);
  const [openFive, setOpenFive] = useState(false);

  const setOne = [db2, db3, db4, db5, db6, db7, db8];
  const [imageSetOne, setImageSetOne] = useState([
    { src: db2, width: 1200, height: 800 },
    { src: db3, width: 1200, height: 800 },
    { src: db4, width: 1200, height: 800 },
    { src: db5, width: 1200, height: 800 },
    { src: db6, width: 1200, height: 800 },
    { src: db7, width: 1200, height: 800 },
    { src: db8, width: 1200, height: 800 },
  ]);

  const setTwo = [hb1, hb2, hb3, hb4];
  const [imageSetTwo, setImageSetTwo] = useState([
    { src: hb1, width: 1200, height: 800 },
    { src: hb2, width: 1200, height: 800 },
    { src: hb3, width: 1200, height: 800 },
    { src: hb4, width: 1200, height: 800 },
  ]);

  const setThree = [bl1, bl4, bl2, bl3];
  const [imageSetThree, setImageSetThree] = useState([
    { src: bl1, width: 1200, height: 800 },
    { src: bl4, width: 1200, height: 800 },
    { src: bl2, width: 1200, height: 800 },
    { src: bl3, width: 1200, height: 800 },
  ]);

  const setFour = [bb1, bb2, bb3, bb4];
  const [imageSetFour, setImageSetFour] = useState([
    { src: bb1, width: 1200, height: 800 },
    { src: bb2, width: 1200, height: 800 },
    { src: bb3, width: 1200, height: 800 },
    { src: bb4, width: 1200, height: 800 },
  ]);

  const setFive = [ui2, ui1];
  const [imageSetFive, setImageSetFive] = useState([
    { src: ui2, width: 1200, height: 800 },
    { src: ui1, width: 1200, height: 800 },
  ]);

  const shuffleImageSetOneByIndex = (index: number) => {
    const newImages = [
      ...imageSetOne.slice(index),
      ...imageSetOne.slice(0, index),
    ];
    setImageSetOne(newImages);
  };

  const shuffleImageSetTwoByIndex = (index: number) => {
    const newImages = [
      ...imageSetTwo.slice(index),
      ...imageSetTwo.slice(0, index),
    ];
    setImageSetTwo(newImages);
  };

  const shuffleImageSetThreeByIndex = (index: number) => {
    const newImages = [
      ...imageSetThree.slice(index),
      ...imageSetThree.slice(0, index),
    ];
    setImageSetThree(newImages);
  };

  const shuffleImageSetFourByIndex = (index: number) => {
    const newImages = [
      ...imageSetFour.slice(index),
      ...imageSetFour.slice(0, index),
    ];
    setImageSetFour(newImages);
  };

  const shuffleImageSetFiveByIndex = (index: number) => {
    const newImages = [
      ...imageSetFive.slice(index),
      ...imageSetFive.slice(0, index),
    ];
    setImageSetFive(newImages);
  };

  const resetImageSetOne = () => {
    setImageSetOne([
      { src: db2, width: 1200, height: 800 },
      { src: db3, width: 1200, height: 800 },
      { src: db4, width: 1200, height: 800 },
      { src: db5, width: 1200, height: 800 },
      { src: db6, width: 1200, height: 800 },
      { src: db7, width: 1200, height: 800 },
      { src: db8, width: 1200, height: 800 },
    ]);
  };

  const resetImageSetTwo = () => {
    setImageSetTwo([
      { src: hb1, width: 1200, height: 800 },
      { src: hb2, width: 1200, height: 800 },
      { src: hb3, width: 1200, height: 800 },
      { src: hb4, width: 1200, height: 800 },
    ]);
  };

  const resetImageSetThree = () => {
    setImageSetThree([
      { src: bl1, width: 1200, height: 800 },
      { src: bl4, width: 1200, height: 800 },
      { src: bl2, width: 1200, height: 800 },
      { src: bl3, width: 1200, height: 800 },
    ]);
  };

  const resetImageSetFour = () => {
    setImageSetFour([
      { src: bb1, width: 1200, height: 800 },
      { src: bb2, width: 1200, height: 800 },
      { src: bb3, width: 1200, height: 800 },
      { src: bb4, width: 1200, height: 800 },
    ]);
  };

  const resetImageSetFive = () => {
    setImageSetFive([
      { src: ui2, width: 1200, height: 800 },
      { src: ui1, width: 1200, height: 800 },
    ]);
  };

  return (
    <>
      <Link to="/" style={{ textDecoration: "none" }}>
        <p className="ExpHomeButton">home</p>
      </Link>
      <div className="Portfolio">
        <div className="PortfolioBox">
          <div className="PortfolioContainer">
            <Lightbox
              open={openOne}
              close={() => {
                setOpenOne(false);
                resetImageSetOne();
              }}
              slides={imageSetOne}
            />
            <div className="TitleRow">
              <h1 className="PortfolioBoxTitle">Dear Blueno</h1>
              <a href="https://dearblueno.com" className="TitleLink">
                (Site)
              </a>
              <a
                href="https://github.com/Dear-Blueno/dearblueno"
                className="TitleLink"
              >
                (Code)
              </a>
            </div>
            <p className="PortfolioBoxText">
              An anonymous post board for students and community members of
              Brown University. Dear Blueno is built with a{" "}
              <b>React, Next.js (TypeScript) frontend.</b> Featured in the{" "}
              <b>Brown Daily Herald, the Washington Free Beacon,</b> and others.
              Functionality includes events, bookmarking, notifications, profile
              pages, and more. Dear Blueno maintains{" "}
              <b style={{ textDecoration: "underline" }}>
                more than 20,000 visits per week.
              </b>
            </p>
            <p className="PortfolioBoxText">
              All images shown below are screenshots of the live site or mobile
              PWA.
            </p>
            <div className="PortfolioBoxImages">
              {setOne.map((image, index) => (
                <div>
                  <img
                    key={index}
                    src={image}
                    alt="portfolio"
                    className="PortfolioBoxImage"
                    onClick={() => {
                      shuffleImageSetOneByIndex(index);
                      setOpenOne(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="PortfolioBox">
          <div className="PortfolioContainer">
            <Lightbox
              open={openTwo}
              close={() => {
                setOpenTwo(false);
                resetImageSetTwo();
              }}
              slides={imageSetTwo}
            />
            <div className="TitleRow">
              <h1 className="PortfolioBoxTitle">Hibearnation</h1>
              <a href="https://hibearnation.tech" className="TitleLink">
                (Site)
              </a>
              <a
                href="https://github.com/NicholasBottone/hibearnation"
                className="TitleLink"
              >
                (Code)
              </a>
            </div>
            <p className="PortfolioBoxText">
              A <b>T3 (Next.js, tRPC, Prisma) webapp</b> that aims to provide a
              comprehensive (unofficial) information hub for Brown University's
              residence halls by providing a platform for students to share
              their experiences and knowledge about Brown's residence halls
              through the form of user-generated reviews and photos.
              Hibearnation has accumulated <b>more than 300 users</b> since it's
              creation and deployment.
            </p>
            <p className="PortfolioBoxText">
              Hibearnation was <b>Category Winner at Hack@Brown 2023</b>, Brown
              University's annual hackathon.
            </p>
            <div className="PortfolioBoxImages">
              {setTwo.map((image, index) => (
                <div>
                  <img
                    key={index}
                    src={image}
                    alt="portfolio"
                    className="PortfolioBoxImage"
                    onClick={() => {
                      shuffleImageSetTwoByIndex(index);
                      setOpenTwo(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="PortfolioBox">
          <div className="PortfolioContainer">
            <Lightbox
              open={openThree}
              close={() => {
                setOpenThree(false);
                resetImageSetThree();
              }}
              slides={imageSetThree}
            />
            <div className="TitleRow">
              <h1 className="PortfolioBoxTitle">Blots</h1>
              <a href="https://blots.pages.dev/" className="TitleLink">
                (Site)
              </a>
            </div>
            <p className="PortfolioBoxText">
              An interactive art experience inspired by Rorshach inkblots. Blots
              presents generative shapes{" "}
              <b>procedurally rendered with a custom Processing script.</b>{" "}
              Featuring a <b>React, TailwindCSS frontend</b> with{" "}
              <b>serverless Firebase database calls</b> for data persistence.
            </p>
            <p className="PortfolioBoxText">
              Since it's start, Blots has had more than <b>250 participants.</b>
            </p>
            <div className="PortfolioBoxImages">
              {setThree.map((image, index) => (
                <div>
                  <img
                    key={index}
                    src={image}
                    alt="portfolio"
                    className="PortfolioBoxImage"
                    onClick={() => {
                      shuffleImageSetThreeByIndex(index);
                      setOpenThree(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="PortfolioBox">
          <div className="PortfolioContainer">
            <Lightbox
              open={openFour}
              close={() => {
                setOpenFour(false);
                resetImageSetFour();
              }}
              slides={imageSetFour}
            />
            <div className="TitleRow">
              <h1 className="PortfolioBoxTitle">Birbs@Brown Animations</h1>
              <a
                href="https://www.instagram.com/birbsatbrown/"
                className="TitleLink"
              >
                (Instagram)
              </a>
            </div>
            <p className="PortfolioBoxText">
              A set of <b>lightweight Processing animations</b> for Birbs@Brown,
              a popular Brown community group. Runnable in realtime via the
              browser with minimal compute power. Used in promotional materials
              and social media by Birbs@Brown, viewed by an estimated{" "}
              <b>~5,000 people.</b>
            </p>
            <p className="PortfolioBoxText">
              Animations converted to GIFs for display below.
            </p>
            <div className="PortfolioBoxImages">
              {setFour.map((image, index) => (
                <div>
                  <img
                    key={index}
                    src={image}
                    alt="portfolio"
                    className="PortfolioBoxImage"
                    onClick={() => {
                      shuffleImageSetFourByIndex(index);
                      setOpenFour(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="PortfolioBox">
          <div className="PortfolioContainer">
            <Lightbox
              open={openFive}
              close={() => {
                setOpenFive(false);
                resetImageSetFive();
              }}
              slides={imageSetFive}
            />
            <div className="TitleRow">
              <h1 className="PortfolioBoxTitle">
                CS1300: UI/UX Presentation Slides
              </h1>
              <a
                href="https://cs.brown.edu/courses/csci1300/"
                className="TitleLink"
              >
                (Course)
              </a>
            </div>

            <p className="PortfolioBoxText">
              Slides created as part of my{" "}
              <b>
                Teaching Assistant role for CS1300: User Interfaces and User
                Experience
              </b>{" "}
              at Brown University. Slides demonstrate the concept of
              <b> optimistic updating</b> in the context of a full-stack
              application, a critical aspect of modern web development.
            </p>
            <p className="PortfolioBoxText">
              Presented in labs along with corresponding technical examples to
              the total class of <b>~450 students.</b>
            </p>
            <div className="PortfolioBoxImages">
              {setFive.map((image, index) => (
                <div>
                  <img
                    key={index}
                    src={image}
                    alt="portfolio"
                    className="PortfolioBoxImage"
                    onClick={() => {
                      shuffleImageSetFiveByIndex(index);
                      setOpenFive(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
