import "./ExperiencePage.css";
import ContentBox from "./ContentBox";
import { Link } from "react-router-dom";

const sunlabBody = `Employed Sunlab Consultant / Head Sunlab Consultant for the Brown University CS Department.

Responsible for maitaining and debugging Brown University's many department machines, largely running 64-bit Debian Linux. \
Helped students with hardware incompatibilities, shell navigation, and general software troubleshooting. Managed the use and \
reservations of Brown's Grid Resources, including 25 GPU servers and 107 nodes.

Accepted additional responsibility as Head Consultant in March 2022. Worked alongside the CS Department TStaff in coordinating \
the improvement of the school's labs. Personally managed the employment of >20 other consultants.

Languages/Technologies: Bash, FastX, PuTTY, Pageant, SSH, React. 

`;

const uiuxBody = `UTA for CS1300: User Interfaces & User Experience.

Led development of assignments like Studio 6: Flutter, a comprehensive introduction to the Flutter framework. \
Held studio sessions with >20 students weekly, graded assignments, and gave feedback on student projects and portfolios. 

Participated in the teaching of UI Camp, a weekend-long workshop for Brown and RISD students interested in UI/UX. Served \
>250 students over a 2 day period.

Languages/Technologies: Flutter, Balsamiq, Figma, React, Javascript.

`;

const graphicsBody = `UTA for CS1230/2230: Computer Graphics.

Worked as part of a tight-knit team for the oldest Computer Graphics course in the world, initially developed by Andy van Dam. Led \
development of assignments such as Raytracer, a raytracing engine written in C++, as well as Action, a real-time renderer leveraging \
OpenGL. Held office hours, graded assignments, and hosted mentor hours wherein I helped bridge conceptual misunderstanding in signal processing, \
linear algebra, and computer graphics.

Languages/Technologies: C++, OpenGL, GLSL, Python, Typescript.

`

const blockchainBody = `UTA for CS1951L: Blockchains and Cryptocurrencies

Helped develop assignments such as Chain, a scalable blockchain storage solution in Go and Auction, a smart contract token exchange written in Solidity. \
Created communication tools in Python to help students interact with course staff. Held TA hours where I helped students debug their assigments \
and further their conceptual understanding. 

Languages/Technologies: Go, Solidity, Typescript, Python.

`;

const blockchain2Body = `STA for CS1951L: Blockchains and Cryptocurrencies (held concurrently as UTA position)

First ever STA for the course. Developed the ethics curriculum from scratch; made assignments and resources worth \
~20% of the entire course grade. Created the "Distributed Lectures" program which scheduled guest lecturers/interviews \
with individuals such as Charles Hoskinson, the Co-Founder of Ethereum and Founder of Cardano. 

Languages/Technologies: LaTeX, Excel, Zoom, Google Meetings.

`;

const blockchain3Body = `HTA for CS1951L: Blockchains and Cryptocurrencies 

Head TA for the course, working directly alongside Professor Maurice Herlihy in the development of the course. \
Interviewed, hired, and trained TA staff. Created and graded assignments including written homeworks, labs, and \
projects. Held TA hours to aid class in debugging. Led routine meetings with the course staff to discuss course development and student progress.

Languages/Technologies: Go, Solidity, Typescript, Python.

`;

const webmasterBody = `Webmaster for the Brown University Orchestra (the "BUO").

Helped maintain the BUO website and the social media presence of the Brown University Orchestra, a group of ~110 student musicians. Published and \
edited articles, videos, and photos. Updated website components and wrote comprehensive tests to ensure the reliability of the website.

Languages/Technologies: Javascript, Markdown, React.

`;

const esportsBody = `Esports Co-Captain for a competitive team chartered by Brown Esports, the largest club on campus.

Developed a competitive team of ~7 players, including a captain and a coach. Managed the team's social media presence, and helped \
organize events, practices, and tournaments. Led discussions and team building exercises.

`;

function ExperiencePage() {
  return (
    <div className="ExperiencePage">
      <Link to="/" style={{ textDecoration: "none" }}>
        <p className="ExpHomeButton">home</p>
      </Link>
      <div className="ContentBoxContainer">
        <ContentBox
          title="Head Sunlab Consultant"
          time="January 2022 - Present"
          body={sunlabBody}
          link1="https://cs.brown.edu/degrees/undergrad/jobs/consult/"
          link1exp="Sunlab Consultant Website"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="Undergraduate Teaching Assistant"
          time="May 2023 - Present"
          body={graphicsBody}
          link1="https://cs.brown.edu/courses/cs123/"
          link1exp="CS1230 Course Website"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="Head Teaching Assistant"
          time="November 2022 - May 2023"
          body={blockchain3Body}
          link1="https://csci1951l-spring2023.vercel.app/"
          link1exp="CS1951L Course Website (2023)"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="Undergraduate Teaching Assistant"
          time="August 2022 - December 2022"
          body={uiuxBody}
          link1="https://cs.brown.edu/courses/csci1300/"
          link1exp="CS1300 Course Website"
          link2="https://uxfactor.cs.brown.edu/"
          link2exp="The UX Factor"
        />
        <ContentBox
          title="Undergraduate Teaching Assistant"
          time="November 2021 - May 2022"
          body={blockchainBody}
          link1="https://csci1951l-spring2022.vercel.app/"
          link1exp="CS1951L Course Website (2022)"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="Ethics Teaching Assistant"
          time="November 2021 - May 2022"
          body={blockchain2Body}
          link1="https://www.youtube.com/watch?v=jnZRAil_j2A"
          link1exp="Distributed Lectures YouTube"
          link2=""
          link2exp=""
        />
        <ContentBox
          title="Webmaster"
          time="September 2021 - Present"
          body={webmasterBody}
          link1="https://www.brown.edu/Departments/Music/sites/orchestra/"
          link1exp="BUO Website"
          link2="https://github.com/HyperKids/brown-orchestra-blog"
          link2exp="BUO GitHub"
        />
        <ContentBox
          title="Esports Co-Captain"
          time="March 2021 - Present"
          body={esportsBody}
          link1="https://brownesports.org/"
          link1exp="Brown Esports Website"
          link2=""
          link2exp=""
        />
        <br />
      </div>
    </div>
  );
}

export default ExperiencePage;
