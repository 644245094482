import "./LinkRow.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

type LinkRowProps = {
  within: boolean;
};

function LinkRow(props: LinkRowProps) {
  const [star1, setStarOne] = useState("*");
  const [star2, setStarTwo] = useState("*");
  const randChars = "#&%^$@!~";

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.within) {
        if (Math.random() < 0.5) {
          setStarOne(randChars[Math.floor(Math.random() * randChars.length)]);
        }
        if (Math.random() < 0.5) {
          setStarTwo(randChars[Math.floor(Math.random() * randChars.length)]);
        }
      }
    }, 300);
    return () => clearInterval(interval);
  }, [props.within]);

  return (
    <div className="LinkRow">
      <p className="LinkEdge">{star1}</p>
      <Link to="/about" style={{ textDecoration: "none" }}>
        <p className="LinkText">ABOUT</p>
      </Link>
      <Link to="/projects" style={{ textDecoration: "none" }}>
        <p className="LinkText">PROJECTS</p>
      </Link>
      <Link to="/experience" style={{ textDecoration: "none" }}>
        <p className="LinkText">EXPERIENCE</p>
      </Link>
      <p className="LinkEdge">{star2}</p>
    </div>
  );
}

export default LinkRow;
