import "./ContentBox.css";

interface ContentBoxProps {
  title: string;
  time: string;
  body: string;
  link1: string;
  link2?: string;
  link1exp: string;
  link2exp?: string;
}

function ContentBox(props: ContentBoxProps) {
  return (
    <div className="ContentBox">
      <div className="ContentText">
        <div className="ContentHeader">
          <p className="ContentTitle">{props.title}</p>
          <p className="ExperienceTime">{props.time}</p>
        </div>
        <p className="ContentBody">{props.body}</p>
        <div className="LinkBox">
          <a className="ContentLink" href={props.link1}>
            {props.link1exp}
          </a>
          {props.link2 && <a className="ContentLink" href={props.link2}>
            {props.link2exp}
          </a>}
        </div>
      </div>
    </div>
  );
}

export default ContentBox;
